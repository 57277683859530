<template>
  <div
    class=""
    style="
      height: 90vh;
      padding: 0px 0% 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    "
  >
    <div
      v-if="!isQuizCreationLoading"
      class="quizell-breadcrumbs"
      style="margin: 0px 14px"
    >
      <div
        class="quizell-breadcrumb-item"
        :class="[currentStep >= index + 1 ? 'current' : '']"
        v-for="(item, index) in breadcrumbsItems"
        :key="item"
      >
        <span class="number"
          ><i class="fa-solid fa-check" v-if="currentStep > index + 1"></i>
          <span v-else> {{ index + 1 }}</span></span
        >
        <span class="label">{{ item }}</span>
      </div>
    </div>
    <div class="pb-3" v-if="!isQuizCreationLoading">
      <div
        class="d-flex flex-column justify-content-end px-5"
        style="padding: 0px 45px"
        v-if="currentStep == 1"
      >
        <div class="">
          <div class="quizell-ai-title">Let’s Get Started on Your Quiz!</div>
          <div class="d-flex align-items-center mt-2" style="gap: 40px">
            <div class="quizell-ai-info d-flex align-items-center">
              <span
                class="quizell-ai-check m-0 mr-2"
                style="
                  color: rgba(77, 27, 126, 1);
                  background: rgba(77, 27, 126, 0.1);
                "
              >
                <i class="fa-solid fa-check"></i>
              </span>
              Be Clear
            </div>
            <div class="quizell-ai-info d-flex align-items-center">
              <span
                class="quizell-ai-check m-0 mr-2"
                style="
                  color: rgba(77, 27, 126, 1);
                  background: rgba(77, 27, 126, 0.1);
                "
              >
                <i class="fa-solid fa-check"></i>
              </span>
              Know Your Audience
            </div>
            <div class="quizell-ai-info d-flex align-items-center">
              <span
                class="quizell-ai-check m-0 mr-2"
                v-b-tooltip
                title="Describe your quiz’s context, such as event details for a registration form or product specifics a recommendation quiz"
                style="
                  color: rgba(77, 27, 126, 1);
                  background: rgba(77, 27, 126, 0.1);
                "
                ><i class="fa-solid fa-check"></i>
              </span>
              Context Matter
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div
            class="col-12 col-md-4"
            v-for="(info, index) in getStartedCardInfo"
            :key="index"
          >
            <div
              class="card"
              style="
                border-radius: 12px;
                border: 1px solid rgba(24, 25, 28, 0.08);
                background: #f9f9f9;
              "
            >
              <div class="card-body info">
                {{ info }}
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3" style="border-radius: 12px">
          <div class="card-body" style="padding: 16px">
            <div class="d-flex justify-content-center align-items-start">
              <div class="" style="flex: 1">
                <textarea
                  name=""
                  v-model="QuizPayload.prompts.i_want_to_create"
                  class="w-100 resize-ta importQuizTextArea"
                  placeholder="Describe the quiz you want to create, including its theme and  purpose..."
                  id=""
                ></textarea>
              </div>
              <div class="">
                <Button
                  :disabled="QuizPayload.prompts.i_want_to_create.length <= 5"
                  @click="steps(2)"
                  >Continue</Button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <Step2Question
        :QuizPayload="QuizPayload"
        class="px-5"
        style="padding: 0px 45px"
        v-else-if="currentStep == 2"
        @input="
          (text) => {
            step2Text = text;
          }
        "
        @click="steps(3)"
      ></Step2Question>
      <Step3Customization
        :QuizPayload="QuizPayload"
        style="padding: 0px 45px"
        @click="steps(4)"
        v-else-if="currentStep == 3"
      ></Step3Customization>
      <Step4CollectData
        :QuizPayload="QuizPayload"
        style="padding: 0px 45px"
        @click="createQuiz"
        v-else-if="currentStep == 4"
      ></Step4CollectData>
    </div>

    <div v-else class="w-100 mt-4" style="min-height: 35vh">
      <div
        class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
      >
        <div class="" style="height: 250px; width: 100%; position: relative">
          <MagicWandLoader />
        </div>

        <div class="pt-3">
          <p class="StepTitle text-center">Crafting Your Quiz</p>
          <p class="StepTitle text-center" style="font-size: 18px">
            Please bear with us as this process may require some time.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MagicWandLoader from "../../../components/MagicWandLoader.vue";
import Button from "../Layout/Button.vue";
import axios from "axios";
import { mapMutations } from "vuex";
import Step2Question from "./CreateAIQuizComp/Step2Question.vue";
import Step3Customization from "./CreateAIQuizComp/Step3Customization.vue";
import Step4CollectData from "./CreateAIQuizComp/Step4CollectData.vue";
// import Step5EndPage from './CreateAIQuizComp/Step5EndPage.vue';
// import SelectQuizProducts from './CreateAIQuizComp/SelectQuizProducts.vue';
export default {
  props: {
    stepArray: Number,
  },
  watch: {
    stepArray(newValue) {
      this.currentStep = newValue;
      // this.$emit('updateStep',newValue)
    },
  },
  data() {
    return {
      QuizPayload: {
        prompts: {
          industry_name: "",
          i_want_to_create: "",
          i_want_to_ask_my_audience_about: "",
        },
        lead_page_fields: {
          // add_lead_page: false,
          email: false,
          full_name: false,
          phone_number: false,
          terms_and_service: false,
          terms_and_service_link: "",
        },
        design: {
          primary_color: "#D1D1D1",
          secondary_color: "#F8F9FA",
          background_color: "#FFFFFF",
          button_text_color: "#767575",
          text_color: "#000000",
          font: "Poppins",
          language: "en",
          currency: 1,
          image: "",
        },
      },
      getStartedCardInfo: [
        "“Create a 5-question quiz to match customers with ideal beauty products based on their preferences and skin type”.",
        "“Design a quick quiz to determine which famous historical figure a person resembles, based on their choices and interests”.",
        "“Set up a short quiz on solar system facts, using true/false and multiple choice questions to test knowledge”.",
      ],
      breadcrumbsItems: [
        "Quiz Basics",
        "Questions",
        "Customization",
        "Collect Data",
      ],
      isQuizCreationLoading: false,
      selectedText: "",
      listActive: false,
      selectedItems: {},
      currentStep: this.stepArray,
    };
  },
  components: {
    Button,
    Step3Customization,
    Step2Question,
    MagicWandLoader,
    Step4CollectData,
    // Step5EndPage,
    // SelectQuizProducts
  },
  computed: {
    isColorValid() {
      // Regular expression for HEX color (e.g., #ffffff or #fff)
      const hexRegex = /^#([0-9a-f]{3}){1,2}$/i;

      // Regular expression for RGB color (e.g., rgb(255, 255, 255))
      const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/i;

      // Regular expression for linear gradient (e.g., linear-gradient(to right, #ffffff, #000000))
      const linearGradientRegex =
        /^linear-gradient\((to (right|left|top|bottom)|((\d+(\.\d+)?deg))),\s*#([0-9a-f]{3}){1,2},\s*#([0-9a-f]{3}){1,2}\)$/i;

      // Test the inputText against the regular expressions
      return (
        hexRegex.test(this.primaryColor) ||
        rgbRegex.test(this.primaryColor) ||
        linearGradientRegex.test(this.primaryColor)
      );
    },
    isTNCValid() {
      if (
        this.QuizPayload.lead_page_fields.terms_and_service &&
        this.QuizPayload.lead_page_fields.terms_and_service_link !== ""
      ) {
        let pattern = /^((http|https|ftp):\/\/)/;
        return pattern.test(
          this.QuizPayload.lead_page_fields.terms_and_service_link
        );
      }
      return false;
    },
    isTnCIncluded() {
      return this.QuizPayload.lead_page_fields.terms_and_service;
    },
    isStep1Valid() {
      return this.QuizPayload.prompts.i_want_to_create.length > 3;
    },
    isFormValid() {
      return this.isTnCIncluded
        ? this.isTNCValid && this.isStep1Valid
        : this.isStep1Valid;
    },

    isLeadPageElementAdded() {
      const { email, full_name, phone_number } =
        this.QuizPayload.lead_page_fields;

      return (
        email ||
        full_name ||
        phone_number ||
        (this.isTnCIncluded && this.isTNCValid)
      );
    },
  },
  mounted() {
    let textareaElement = document.querySelector(".resize-ta");
    textareaElement.addEventListener("keyup", () => {
      textareaElement.style.height =
        this.calcHeight(textareaElement.value) + "px";
    });
  },
  methods: {
    calcHeight(value) {
      let numberOfLineBreaks = (value.match(/\n/g) || []).length;
      let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
      return newHeight;
    },
    steps(step) {
      this.currentStep = step;
      this.$emit("updateStep", step);
    },
    ...mapMutations(["setQuizTitle"]),
    onSearchboxFocus() {
      this.listActive = true;
      document.addEventListener("click", this.onDocumentClick);
    },
    onDocumentClick(event) {
      if (this.$refs.container)
        if (!this.$refs.container.contains(event.target)) {
          this.listActive = false;
        }
    },
    remove(index) {
      this.selected.splice(index, 1);
    },

    async createQuiz() {
      if (this.isFormValid) {
        try {
          this.isQuizCreationLoading = true;
          const formData = new FormData();

          // Loop through the QuizPayload object and append each key-value pair to the FormData object
          for (const section in this.QuizPayload) {
            const sectionData = this.QuizPayload[section];
            for (const key in sectionData) {
              formData.append(`${section}[${key}]`, sectionData[key]);
            }
          }
          
          formData.append(`lead_page_fields[add_lead_page]`,this.isLeadPageElementAdded);



          const response = await axios.post(`/quiz/ai-generate`, formData);

          if (response.status == 200) {
            const { quiz_key } = response.data.data;
            const { title } = response.data.data.ai_generated;
            if (quiz_key) {
              this.setQuizTitle(title);
              let url =
                window.location.origin + `/customize/create/${quiz_key}`;
              window.open(url, "_self");
            }
          } else {
            this.$toasted.show("Something went wrong! Please try again", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } catch (error) {
          if (error.response) {
            console.log("Error Response", error.response);
            this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isQuizCreationLoading = false;
        }
      } else {
        if (!this.isTNCValid) {
          this.$toasted.show("Terms and conditions link is not valid", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else {
          this.$toasted.show("Provided inputs are not valid!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
  },
};
</script>

<style scoped>
.importQuizTextArea {
  border: none;
  outline: none;
}
.importQuizTextArea:active {
  border: none;
  outline: none;
}
.info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.quizell-ai-info {
  color: #1f1f28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.56px;
}
.quizell-ai-title {
  color: #1f1f28;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.88;
}
.quizell-breadcrumbs {
  color: #b3afb6;
  display: flex;
  font-size: 14px;
  position: relative;
  border: 1px solid #b3afb6;
  border-radius: 12px;
}

.quizell-breadcrumb-item {
  padding-left: 20px;
  flex-grow: 1;
  letter-spacing: 0.56px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.quizell-breadcrumb-item:first-child {
  padding-left: 10px;
}

.quizell-breadcrumb-item .number,
.quizell-ai-check {
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid;
  border-radius: 50%;
  text-align: center;
  float: left;
  margin: 13px 0 0 10px;
}

.quizell-breadcrumb-item .label {
  display: block;
  padding: 14px 0 14px 10px;
  float: left;
  letter-spacing: 0.56px;
}

.quizell-breadcrumb-item:not(:last-child):after {
  content: "";
  display: block;
  width: 33px;
  height: 33px;
  transform: rotate(130deg) skew(-10deg);
  float: right;
  position: relative;
  top: 8px;
  left: 17px;
  border-top: 1px solid #b3afb6;
  border-left: 1px solid #b3afb6;
  background-color: #fff;
}

.quizell-breadcrumb-item.current,
.quizell-breadcrumb-item.current::after {
  background-color: #f0e5fb;
  color: rgba(77, 27, 126, 1);
}
.quizell-breadcrumb-item.current:first-child {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}
.quizell-breadcrumb-item.current .number {
}

.quizell-progress-bar .title {
  color: #ccc;
  font-weight: 600;
  font-size: 14px;
}

/* .collectBtnContainer{
  background: red;
} */
.collectBtnContainer .singleCollectBtn {
  padding: 5px 10px;
  min-width: 80px;
  border-radius: 30px;
  border: 1px solid #c3c6d4;
  cursor: pointer;
}
.cancelLeads {
  color: #8a8a8a;
  text-decoration: underline !important;
  font-size: 16px;
  background: transparent;
}
.collectBtnContainer .singleCollectBtn.selectedBtn {
  color: #fff;
  background: #4d1b7e;
  border: none;
}

#primary-color,
#secondary-color {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#primary-color::-webkit-color-swatch,
#secondary-color::-webkit-color-swatch {
  border-radius: 50%;
  border: 1px solid #4d1b7e;
}
#primary-color::-moz-color-swatch,
#secondary-color::-moz-color-swatch {
  border-radius: 50%;
  border: 1px solid #4d1b7e;
}
.active-progressbar .title {
  color: #4d1b7e !important;
  font-weight: 600;
  font-size: 14px;
}
.quizell-progress-bar .progress-bar1-line .active {
  width: 0%;
  transition: 0.4s ease all;
}
.active-progressbar .progress-bar1-line .active {
  width: 100%;
  background: #4d1b7e !important;
  height: 100%;
}
.rectangle-list span:hover {
  background: #eee;
}
.rectangle-list .active:before {
  background: #4d1b7e !important;
  color: #fff;
}
.rectangle-list span:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -2.5em;
  top: 50%;
  margin-top: -1em;

  background: #eee;
  height: 2em;
  width: 2em;
  color: #0c0c0c;
  line-height: 2em;
  text-align: center;
  font-size: 14px;
}

.rectangle-list span:after {
  position: absolute;
  content: "";
  border: 0.5em solid transparent;
  left: -1em;
  top: 50%;
  margin-top: -0.5em;
  transition: all 0.3s ease-out;
}

.rectangle-list span:hover:after {
  left: -0.5em;
  border-left-color: #ccc;
}
.rectangle-list span.active:after {
  left: -0.5em;
  border-left-color: #4d1b7e;
}
.suggestion-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px; /* Adjust max-height as needed */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.custom-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer !important;
}
/* Add your custom CSS styling here */
.popover-list {
  list-style-type: none;
  padding: 0;

  position: absolute;
  z-index: 11111111111;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  max-height: 250px;
  min-height: 50px;
  overflow-y: scroll;
}

.popover-item {
  padding: 8px;
}

.popover-item:hover {
  background-color: #f0f0f0;
}

.filterSearch {
  padding: 8px;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 4px;
  margin-bottom: 8px;
  margin: 0px;
}

button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.filterSearch:focus-visible {
  border: none;
  outline: none;
}
.multiFilter {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background: white;
  transition: 0.3s ease all;

  width: 100%;
}
.multiFilter:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.tag {
  background: #e3e3e3;
  border-radius: 0.5rem;
  font-size: 14px;
  padding: 0px 10px !important;
  max-width: 100%;
}
.cross-icon {
  color: #8a8a8a;
}
.cross-icon:hover {
  color: black;
  cursor: pointer;
}
.tag:hover {
  background: #d4d3d3;
  border-radius: 0.5rem;
  font-size: 14px;
  padding: 0px 10px !important;
}
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #ffa201;
  border-color: #ffa201;
}
.radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
</style>
